import React, { useCallback, useMemo, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { Box, IconButton, Typography } from '@mui/material';
import clsx from 'clsx';
import { useIntl } from 'react-intl';

import { ArrowLeft, ArrowRight, Calendar as CalendarIcon } from '@linetweet/linetweet-ui';

import { TimeHelper } from 'utils';

import styles from './DatePicker.module.scss';

type Props = {
  date: string;
  view: 'day' | 'week';
  setDate: (value: string) => void;
  timeZone: string;
};

export function DatePicker({ view, date, setDate, timeZone }: Props) {
  const intl = useIntl();
  const [datePickerOpen, setDatePickerOpen] = useState<boolean>(false);

  const onChange = useCallback((newDate: Dayjs | null) => {
    if (newDate) {
      setDate(TimeHelper.toStandardFormat(newDate));
    }
  }, []);

  const value = useMemo(() => dayjs(date), [date]);

  const setNextDate = useCallback(() => {
    setDate(TimeHelper.toStandardFormat(dayjs(date).add(1, view)));
  }, [date, timeZone]);

  const setPrevDate = useCallback(() => {
    setDate(TimeHelper.toStandardFormat(dayjs(date).subtract(1, view)));
  }, [date, timeZone]);

  const currentDateString = useMemo(() => TimeHelper.toStandardFormat(dayjs().startOf('day')), []);

  const onOpen = useCallback(() => setDatePickerOpen(true), []);

  const onClose = useCallback(() => setDatePickerOpen(false), []);

  return (
    <Box display="flex" className={styles.container}>
      <IconButton className={styles.calendarButton} onClick={onOpen}>
        <CalendarIcon width={18} height={18} />
      </IconButton>
      <DesktopDatePicker
        open={datePickerOpen}
        onClose={onClose}
        inputFormat="D MMM YYYY"
        value={value}
        onChange={onChange}
        disableMaskedInput
        renderInput={(params) => (
          <Typography
            fontWeight={400}
            variant="h6"
            ref={params.inputRef}
            onClick={onOpen}
            classes={{
              root: styles.label,
            }}
          >
            {/* {renderValue} */}
          </Typography>
        )}
      />
      <IconButton onClick={setPrevDate} className={styles.arrowIconButton}>
        <ArrowLeft width={18} height={18} />
      </IconButton>

      <Typography
        fontWeight={400}
        onClick={() => setDate(currentDateString)}
        className={clsx(styles.todayLabel, {
          [styles.isToday]: currentDateString === date,
        })}
      >
        {intl.formatMessage({ id: 'common.today' })}
      </Typography>
      <IconButton onClick={setNextDate} className={styles.arrowIconButton}>
        <ArrowRight width={18} height={18} />
      </IconButton>
    </Box>
  );
}
